<template>
    <swiper :options="swiperOptions"
        ref="swiperRef">
        <swiper-slide
            v-for="item in picList"
            :key="item.id">
            <el-image
                :src="require('@/assets/images/interface/'+item)"
                fit="scale-down"
                class="interface-carousel-pic">
                <!-- 加载中样式 -->
                <template v-slot:placeholder>
                    <div class="image-slot">
                        <i class="el-icon-loading"></i>
                    </div>
                </template>
                <!-- 加载失败样式 -->
                <template v-slot:error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </swiper-slide>
        <!-- 圆点导航 -->
        <template v-slot:pagination>
            <slot name="pagination"></slot>
        </template>
    </swiper>
</template>

<script>
export default {
    props: {
        picList: {
            type: Array
        },
        swiperOptions: {
            type: Object
        },
    }
}
</script>

<style scoped>
.interface-carousel-pic{
    width: 100%;
    height: 600px;
    padding: 20px;
    background-color: #fff;
}
</style>